import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { fetchVendorDetails } from "../redux/wholesaleRetailVendorSlice";
import { setCurrentVendor } from "../redux/retailerWholesalerCartSlice";
import RetailerWholesalerNavbar from "../components/RetailerWholesalerNavbar";
import { AddShoppingCart, CheckCircle } from "@material-ui/icons";
import {
  Tooltip,
  Badge,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Avatar,
} from "@mui/material";
import { toast } from "react-toastify";
import LinkIcon from "@mui/icons-material/Link";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import ShareIcon from "@mui/icons-material/Share";
import vendorBackgroundBanner from "../assets/vendorBackgroundBanner.png";
import RetailerWholesalerCart from "../components/RetailerWholesalerCart";
import RetailerWholesalerProductModal from "./RetailerWholesalerProductModal";
import placeholderImage from "../assets/retailerImage4.png";

const VendorShopPage = () => {
  const { vendorId } = useParams();
  const dispatch = useDispatch();
  const vendor = useSelector((state) => state.wholesaleRetailVendor.vendor);
  const cart = useSelector(
    (state) => state.retailerWholesalerCart.carts[vendorId] || {}
  );
  const cartCount = Object.values(cart).reduce(
    (total, item) => total + item.quantity,
    0
  );
  const status = useSelector((state) => state.wholesaleRetailVendor.isFetching);
  const error = useSelector((state) => state.wholesaleRetailVendor.error);

  const sectionsNavRef = useRef(null);
  const [activeSection, setActiveSection] = useState(null);
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    dispatch(fetchVendorDetails(vendorId));
    dispatch(setCurrentVendor(vendorId));
  }, [dispatch, vendorId]);

  useEffect(() => {
    if (vendor && vendor.sections && vendor.sections.length > 0) {
      const firstSectionWithProducts = vendor.sections.find(
        (section) => section.products && section.products.length > 0
      );
      if (firstSectionWithProducts) {
        setActiveSection(`section-${firstSectionWithProducts._id}`);
        setTimeout(() => {
          const sectionElement = document.getElementById(
            `section-${firstSectionWithProducts._id}`
          );
          if (sectionElement) {
            const offsetTop =
              sectionElement.offsetTop -
              (sectionsNavRef.current
                ? sectionsNavRef.current.offsetHeight
                : 0) -
              20;
            window.scrollTo({
              top: offsetTop,
              behavior: "smooth",
            });
          }
        }, 100);
      }
    }
  }, [vendor]);

  useEffect(() => {
    if (!vendor || !vendor.sections) return;

    const options = {
      root: null,
      rootMargin: "0px",
      threshold: [0.25, 0.75],
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && entry.intersectionRatio >= 0.75) {
          setActiveSection(entry.target.id);
        }
      });
    }, options);

    vendor.sections.forEach((section) => {
      if (section.products && section.products.length > 0) {
        const element = document.getElementById(`section-${section._id}`);
        if (element) {
          observer.observe(element);
        }
      }
    });

    return () => {
      vendor.sections.forEach((section) => {
        const element = document.getElementById(`section-${section._id}`);
        if (element) {
          observer.unobserve(element);
        }
      });
    };
  }, [vendor]);

  const handleSectionClick = (sectionId) => (event) => {
    event.preventDefault();
    const sectionElement = document.getElementById(sectionId);
    if (sectionElement) {
      sectionElement.scrollIntoView({ behavior: "smooth", block: "start" });
      setActiveSection(sectionId);
    }
  };

  const getLinkClasses = (sectionId) => {
    return activeSection === sectionId ? "active-section" : "";
  };

  const handleProductClick = (product) => {
    setSelectedProduct(product);
  };

  const handleCloseModal = () => {
    setSelectedProduct(null);
  };

  const handleShareClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseShareMenu = () => {
    setAnchorEl(null);
  };

  const handleCopyLink = () => {
    if (!vendor) return;

    const formatProductItem = (item) => {
      const name = item.name;
      const price = `Shs ${item.price.toLocaleString("en-US")}`;
      const dots = ".".repeat(Math.max(3, 35 - (name.length + price.length)));
      return `📦 ${name} ${dots} ${price}`;
    };

    const previewUrl = `${process.env.REACT_APP_CLIENT_URL}/shop/${vendorId}`;

    const today = new Date().toLocaleDateString("en-US", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    });

    const productItems = vendor.sections
      .flatMap(
        (section) =>
          section.products?.filter((product) => product.enabled) || []
      )
      .slice(0, 5);

    const shareText =
      `✨ ${vendor.name} Shop ✨\n` +
      `${today}\n` +
      `${"─".repeat(30)}\n\n` +
      `${productItems.map(formatProductItem).join("\n")}\n\n` +
      `${"─".repeat(30)}\n\n` +
      `📱 View full catalog and order:\n` +
      `${previewUrl}\n\n` +
      `💫 Download Lusuku:\n` +
      `Android: https://play.google.com/store/apps/details?id=com.lusuku` +
      `\niOS: https://apps.apple.com/ug/app/lusuku/id1622613658`;

    navigator.clipboard
      .writeText(shareText)
      .then(() => {
        toast.success("Shop details copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
        toast.error("Failed to copy to clipboard");
      });
    setAnchorEl(null);
  };

  const handleWhatsAppShare = () => {
    if (!vendor) return;

    const formatProductItem = (item) => {
      const name = item.name;
      const price = `Shs ${item.price.toLocaleString("en-US")}`;
      const dots = ".".repeat(Math.max(3, 35 - (name.length + price.length)));
      return `📦 ${name} ${dots} ${price}`;
    };

    const previewUrl = `${process.env.REACT_APP_CLIENT_URL}/shop/${vendorId}`;

    const today = new Date().toLocaleDateString("en-US", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    });

    const productItems = vendor.sections
      .flatMap(
        (section) =>
          section.products?.filter((product) => product.enabled) || []
      )
      .slice(0, 5);

    const shareText =
      `✨ ${vendor.name} Shop ✨\n` +
      `${today}\n` +
      `${"─".repeat(30)}\n\n` +
      `${productItems.map(formatProductItem).join("\n")}\n\n` +
      `${"─".repeat(30)}\n\n` +
      `📱 View full catalog and order:\n` +
      `${previewUrl}\n\n` +
      `💫 Download Lusuku:\n` +
      `Android: https://play.google.com/store/apps/details?id=com.lusuku` +
      `\niOS: https://apps.apple.com/ug/app/lusuku/id1622613658`;

    const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(shareText)}`;
    window.open(whatsappUrl, "_blank");
    setAnchorEl(null);
  };

  return (
    <Container>
      <RetailerWholesalerNavbar setIsCartOpen={setIsCartOpen} />
      {status ? (
        <LoadingIndicator>Loading....</LoadingIndicator>
      ) : error ? (
        <ErrorIndicator>Error: {error}</ErrorIndicator>
      ) : (
        vendor && (
          <>
            <VendorInfo>
              <VendorBackground>
                <AvatarWrapper>
                  <StyledAvatar
                    alt={vendor?.name}
                    src={vendor?.imageUrl || placeholderImage}
                  />
                </AvatarWrapper>
                <VendorText>
                  <h1>{vendor.name}</h1>
                  <p>{vendor.address}</p>
                  <p>⭐⭐⭐⭐</p>
                </VendorText>
              </VendorBackground>
              <ShareButton onClick={handleShareClick}>
                <ShareIcon />
              </ShareButton>
              <StyledMenu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleCloseShareMenu}
              >
                <MenuItem onClick={handleCopyLink}>
                  <ListItemIcon>
                    <LinkIcon fontSize="small" style={{ color: "#1976d2" }} />
                  </ListItemIcon>
                  <ListItemText primary="Copy Shop Link" />
                </MenuItem>
                <MenuItem onClick={handleWhatsAppShare}>
                  <ListItemIcon>
                    <WhatsAppIcon
                      fontSize="small"
                      style={{ color: "#25D366" }}
                    />
                  </ListItemIcon>
                  <ListItemText primary="Share on WhatsApp" />
                </MenuItem>
              </StyledMenu>
            </VendorInfo>
            {vendor.isActive ? (
              vendor.sections &&
              vendor.sections.filter(
                (section) =>
                  section.products &&
                  section.products.filter((product) => product.enabled).length >
                    0
              ).length > 0 ? (
                <>
                  <SectionsNav ref={sectionsNavRef}>
                    {vendor.sections
                      .filter(
                        (section) =>
                          section.products &&
                          section.products.filter((product) => product.enabled)
                            .length > 0
                      )
                      .map((section) => (
                        <SectionLink
                          key={section._id}
                          href={`#section-${section._id}`}
                          onClick={handleSectionClick(`section-${section._id}`)}
                          className={getLinkClasses(`section-${section._id}`)}
                        >
                          {section.title}
                        </SectionLink>
                      ))}
                  </SectionsNav>
                  <SectionsContainer>
                    {vendor.sections
                      .filter(
                        (section) =>
                          section.products &&
                          section.products.filter((product) => product.enabled)
                            .length > 0
                      )
                      .map((section) => (
                        <Section
                          key={section._id}
                          id={`section-${section._id}`}
                        >
                          <SectionHeader>
                            <h2>{section.title}</h2>
                            <p className="section-description">
                              || {section.description}
                            </p>
                          </SectionHeader>
                          <ProductsGrid>
                            {section.products
                              .filter((product) => product.enabled)
                              .map((product) => {
                                const isInCart = Boolean(cart[product._id]);
                                return (
                                  <ProductCard
                                    key={product._id}
                                    onClick={() => handleProductClick(product)}
                                  >
                                    <ProductImage
                                      src={product.image}
                                      alt={product.name}
                                      onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src = placeholderImage;
                                      }}
                                    />
                                    <ProductDetails>
                                      <ProductName>{product.name}</ProductName>
                                      <ProductDescription>
                                        {product.description}
                                      </ProductDescription>
                                      <ProductPrice>
                                        {new Intl.NumberFormat("en-UG", {
                                          style: "currency",
                                          currency: "UGX",
                                        }).format(product.price)}
                                      </ProductPrice>
                                    </ProductDetails>
                                    <Tooltip
                                      title={
                                        isInCart
                                          ? "Item in cart"
                                          : "Add to Cart"
                                      }
                                    >
                                      <AddToCartContainer
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleProductClick(product);
                                        }}
                                      >
                                        <ProductUnits>
                                          {product.unit_name}
                                        </ProductUnits>
                                        {isInCart ? (
                                          <InCartIcon />
                                        ) : (
                                          <AddToCartIcon />
                                        )}
                                      </AddToCartContainer>
                                    </Tooltip>
                                  </ProductCard>
                                );
                              })}
                          </ProductsGrid>
                        </Section>
                      ))}
                  </SectionsContainer>
                </>
              ) : (
                <NoProductsMessage>
                  No Active Products at the moment...
                </NoProductsMessage>
              )
            ) : (
              <InactiveMessage>
                This vendor is currently inactive and not accepting orders.
              </InactiveMessage>
            )}
          </>
        )
      )}
      <Tooltip title="View Cart">
        <FloatingCartIcon onClick={() => setIsCartOpen(!isCartOpen)}>
          <Badge badgeContent={cartCount} color="secondary">
            🛒
          </Badge>
        </FloatingCartIcon>
      </Tooltip>
      {isCartOpen && (
        <CartOverlay>
          <RetailerWholesalerCart onClose={() => setIsCartOpen(false)} />
        </CartOverlay>
      )}
      {selectedProduct && (
        <RetailerWholesalerProductModal
          open={Boolean(selectedProduct)}
          onClose={handleCloseModal}
          product={selectedProduct}
        />
      )}
    </Container>
  );
};

export default VendorShopPage;

const Container = styled.div`
  padding: 20px;
  padding-top: 80px; /* Adjust to accommodate navbar height */

  @media (max-width: 768px) {
    padding-top: 165px; /* Adjust for mobile for the vendor banner */
  }
`;

const VendorInfo = styled.div`
  margin-bottom: 20px;
  position: relative;
  height: 120px; /* Adjust height to match the image */
  width: 100%;
  padding-top: 10px;

  @media (max-width: 768px) {
    height: 100px;
  }
`;

const VendorBackground = styled.div`
  background-image: url(${vendorBackgroundBanner});
  background-size: cover;
  background-position: center;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const AvatarWrapper = styled.div`
  position: absolute;
  left: 20px;
  bottom: 20px;
  width: 120px;
  height: 120px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff; /* Optional: set a background color for contrast */
  border-radius: 10px;
  overflow: hidden;
  height: 90px;
`;

const StyledAvatar = styled(Avatar)`
  width: 90%;
  height: 90%;
  border-radius: 8px; /* Ensure this is not 50% for non-circular */
  object-fit: contain;
  padding: 5px;
  box-sizing: border-box;
`;

const VendorText = styled.div`
  color: white;
  text-align: center;
  background-color: rgba(
    0,
    0,
    0,
    0.7
  ); /* Add a semi-transparent background to make text stand out */
  padding: 10px 20px;
  border-radius: 8px;

  h1 {
    font-size: 1.5em;
    margin: 0;
  }

  p {
    margin: 5px 0;
    font-size: 1em;
  }
`;

const ShareButton = styled(IconButton)`
  position: absolute;
  bottom: 20px;
  right: 20px;
  color: black;
  background-color: #f2f2f2;
  padding: 12px;
  transition: all 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 50%;

  &:hover {
    background-color: #22d366;
    transform: scale(1.1);
    color: white;
  }

  @media (max-width: 768px) {
    padding: 8px;
    bottom: 15px;
    right: 15px;
  }

  @media (max-width: 480px) {
    padding: 6px;
    bottom: 10px;
    right: 10px;
  }
`;

const StyledMenu = styled(Menu)`
  .MuiMenuItem-root {
    padding: 12px 24px;

    &:hover {
      background-color: #f5f5f5;

      .MuiListItemIcon-root {
        transform: scale(1.1);
      }
    }
  }

  .MuiListItemIcon-root {
    transition: transform 0.2s ease;
  }
`;

const SectionsNav = styled.nav`
  position: sticky;
  top: 80px; /* Adjust based on the height of your navbar */
  background-color: white;
  z-index: 1000;
  padding: 10px 0;
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid #ccc;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3); /* Add shadow to section nav */
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  @media (max-width: 768px) {
    top: 170px; /* Adjust for mobile */
    overflow-x: auto; /* Enable horizontal scrolling for smaller screens */
    white-space: nowrap; /* Prevent wrapping of section links */
    margin-bottom: 30px; /* Add some space below the sections nav */
    -webkit-overflow-scrolling: touch; /* Enable smooth scrolling on iOS */
    scrollbar-width: none; /* Hide scrollbar for Firefox */
    &::-webkit-scrollbar {
      display: none; /* Hide scrollbar for WebKit browsers */
    }
  }
`;

const SectionLink = styled.a`
  text-decoration: none;
  color: black;
  padding: 10px 20px;
  font-weight: normal;
  transition: all 0.3s ease;

  &.active-section {
    color: #f24405;
    font-weight: bold;
    border-bottom: 2px solid #84b026;
  }

  &:hover {
    background-color: #f0f0f0;
  }

  @media (max-width: 768px) {
    padding: 5px 10px;
    display: inline-block; /* Ensure links are displayed inline */
  }
`;

const SectionsContainer = styled.div`
  margin-top: 2px;
  scroll-snap-type: y mandatory; /* Enable scroll snapping */
  overflow-y: auto; /* Ensure vertical scrolling */
  height: calc(100vh - 160px); /* Ensure it takes up the remaining height */

  @media (max-width: 768px) {
    height: calc(100vh - 270px); /* Adjust for mobile */
    padding-top: 10px; /* Ensure there's spacing below SectionsNav */
  }
`;

const Section = styled.section`
  margin-bottom: 8px;
  padding-top: 22px; /* Increased padding to avoid overlap with product card */
  scroll-snap-align: start; /* Ensure each section snaps into view */
`;

const SectionHeader = styled.div`
  display: flex;
  align-items: baseline;
  margin-bottom: 10px; /* Add space to avoid overlap with product card transition */

  h2 {
    margin: 0;
    font-size: 1.4em;

    @media (max-width: 768px) {
      font-size: 1.2em;
      margin-bottom: 2px;
    }
  }

  .section-description {
    margin: 0 0 0 10px; /* Add some space between title and description */
    font-size: 0.8em; /* Make the description tiny */
    font-style: italic; /* Make the description italic */
    color: #777; /* Lighten the description color */

    @media (max-width: 768px) {
      display: none; /* Hide description on mobile */
    }
  }
`;

const ProductsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Adjusted to 4 cards per row */
  gap: 30px; /* Increase gap between products */

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr); /* Adjust for mobile */
  }
`;

const ProductCard = styled.div`
  border: 0.5px solid #ccc;
  padding: 20px; /* Increased padding for more space inside the card */
  text-align: left; /* Align text to the left */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add subtle shadow for better visual separation */
  border-radius: 8px; /* Add border-radius for rounded corners */
  background-color: white;
  transition: transform 0.2s; /* Add a transition for smooth scaling on hover */
  position: relative; /* For positioning the add to cart icon */
  cursor: pointer; /* Change cursor to pointer on hover */

  &:hover {
    transform: scale(1.05); /* Slightly increase the size on hover */
  }
`;

const ProductImage = styled.img`
  max-width: 100%;
  height: auto;
  border-bottom: 1px solid #eee; /* Add a bottom border for better separation */
  padding-bottom: 15px; /* Add padding to the bottom */
`;

const ProductDetails = styled.div`
  padding-top: 15px; /* Add padding to the top */
`;

const ProductName = styled.h5`
  font-size: 14px;
  margin: 15px 0 5px;
  color: #333;

  @media (max-width: 768px) {
    font-size: 0.85em;
  }
`;

const ProductPrice = styled.p`
  font-size: 1em;
  color: #2e5902;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 0.8em; /* Adjust for mobile */
  }
`;

const ProductDescription = styled.p`
  font-size: 12px;
  color: #777;
  margin: 5px 0;
`;

const AddToCartContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: absolute;
  bottom: 20px;
  right: 20px;

  @media (max-width: 768px) {
    bottom: 10px;
    right: 10px;
  }
`;

const AddToCartIcon = styled(AddShoppingCart)`
  font-size: 2rem;
  color: #f24405;
  cursor: pointer;
  transition: color 0.2s;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  padding: 10px;

  &:hover {
    color: #f24405;
  }

  @media (max-width: 768px) {
    font-size: 1.5rem; /* Adjust size for mobile */
    padding: 8px;
  }

  @media (max-width: 480px) {
    font-size: 1.2rem; /* Adjust size for small mobile screens */
    padding: 6px;
  }
`;

const InCartIcon = styled(CheckCircle)`
  font-size: 2rem;
  color: #84b026;
  cursor: pointer;
  transition: color 0.2s;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  padding: 10px;

  &:hover {
    color: #f24405;
  }

  @media (max-width: 768px) {
    font-size: 1.5rem; /* Adjust size for mobile */
    padding: 8px;
  }

  @media (max-width: 480px) {
    font-size: 1.2rem; /* Adjust size for small mobile screens */
    padding: 6px;
  }
`;

const ProductUnits = styled.span`
  font-size: 0.6rem;
  color: #555;
  margin-bottom: 35px; /* Default margin */
  padding: 8px;
  border-radius: 50%;
  background-color: #eee;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

  @media (max-width: 768px) {
    font-size: 0.5rem;
    margin-bottom: 75px;
  }

  @media (max-width: 480px) {
    font-size: 0.6rem;
    margin-bottom: 56px;
  }

  @media (max-width: 360px) {
    font-size: 0.5rem;
    margin-bottom: 55px;
  }
`;

const FloatingCartIcon = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  font-size: 2rem;
  background-color: #00ff00;
  color: white;
  padding: 15px; /* Increase padding */
  border-radius: 75%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: background-color 0.2s; /* Add a transition for smooth color change on hover */

  &:hover {
    background-color: #02ff88;
  }

  @media (max-width: 768px) {
    font-size: 1.5rem; /* Adjust size for mobile */
    padding: 12px;
  }

  @media (max-width: 480px) {
    font-size: 1.2rem; /* Adjust size for small mobile screens */
    padding: 10px;
    bottom: 10px; /* Adjust position for small mobile screens */
    right: 10px;
  }
`;

const CartOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const LoadingIndicator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 24px;
  color: #333;

  @media (max-width: 768px) {
    font-size: 20px; /* Adjust font size for mobile */
  }

  @media (max-width: 480px) {
    font-size: 16px; /* Adjust font size for small mobile screens */
  }
`;

const ErrorIndicator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 24px;
  color: red;

  @media (max-width: 768px) {
    font-size: 20px; /* Adjust font size for mobile */
  }

  @media (max-width: 480px) {
    font-size: 16px; /* Adjust font size for small mobile screens */
  }
`;

const InactiveMessage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50vh; /* Adjust as needed */
  font-size: 22px;
  font-style: italic;
  color: red;
  text-align: center;
  background-color: #f8d7da;
  border: 1px solid #f5c2c7;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    font-size: 20px; /* Adjust font size for mobile */
    padding: 15px;
  }

  @media (max-width: 480px) {
    font-size: 16px; /* Adjust font size for small mobile screens */
    padding: 10px;
  }

  h1 {
    font-size: 1.5em;
    margin-bottom: 10px;
  }

  p {
    font-size: 1em;
    margin: 0;
    color: #721c24;
  }
`;

const NoProductsMessage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50vh;
  font-size: 18px;
  font-style: italic;
  color: #721c24;
  text-align: center;
  background-color: #f8d7da;
  border: 1px solid #f5c2c7;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  margin: 20px;
  transition: transform 0.3s, box-shadow 0.3s;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 768px) {
    font-size: 20px; /* Adjust font size for mobile */
    padding: 15px;
  }

  @media (max-width: 480px) {
    font-size: 16px; /* Adjust font size for small mobile screens */
    padding: 10px;
  }

  h1 {
    font-size: 1.8em;
    margin-bottom: 10px;
    color: #721c24;
  }

  p {
    font-size: 1.2em;
    margin: 0;
    color: #721c24;
  }

  svg {
    font-size: 3rem;
    color: #721c24;
    margin-bottom: 15px;
  }
`;
