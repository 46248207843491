import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import styled from "styled-components";
import { setCurrentRestaurant } from "../redux/restaurantCartSlice";
import placeholderImage from "../assets/img/menuImage.png";
import { fetchRestaurantDetails } from "../redux/restaurantSlice";
import RestaurantCart from "../components/RestaurantCart";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ShareIcon from "@mui/icons-material/Share";
import LinkIcon from "@mui/icons-material/Link";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import {
  IconButton,
  Badge,
  Tooltip,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Avatar,
} from "@mui/material";
import { toast } from "react-toastify";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import restaurantBackgroundBanner from "../assets/vendorBackgroundBanner.png";
import RestaurantProductDialog from "./RestaurantProductDialog";
import RestaurantNavBar from "../components/RestaurantNavBar";

// Use the ngrok URL instead of localhost
// const API_BASE_URL = "https://4503-102-222-68-196.ngrok-free.app"; // Replace with your actual ngrok URL
const API_BASE_URL = process.env.REACT_APP_SERVER_URL; // Replace with your actual ngrok URL

const RestaurantShopPage = () => {
  const { id } = useParams();
  console.log("id???", id);
  const history = useHistory();
  const dispatch = useDispatch();
  const restaurantDetails = useSelector((state) => state.restaurants.details);
  const selectedActiveRestaurant = useSelector(
    (state) => state.restaurants.selectedRestaurant
  );
  const isFetching = useSelector((state) => state.restaurants.isFetching);
  const cart = useSelector((state) => state.restaurantCart.carts[id] || {});
  const cartCount = Object.values(cart).reduce(
    (total, item) => total + item.quantity,
    0
  );
  const sectionsNavRef = useRef(null);
  const [activeSection, setActiveSection] = useState(null);
  const [isCartVisible, setIsCartVisible] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [imageSrcMap, setImageSrcMap] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    if (id) {
      dispatch(fetchRestaurantDetails(id));
      dispatch(setCurrentRestaurant(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (!selectedActiveRestaurant) {
      history.push("/"); // Navigate to landing page if selectedActiveRestaurant is null
    }
  }, [selectedActiveRestaurant, history]);

  useEffect(() => {
    if (
      restaurantDetails &&
      restaurantDetails.sections &&
      restaurantDetails.sections.length > 0
    ) {
      const firstSectionWithProducts = restaurantDetails.sections.find(
        (section) => section.products && section.products.length > 0
      );
      if (firstSectionWithProducts) {
        setActiveSection(`section-${firstSectionWithProducts._id}`);
        setTimeout(() => {
          const sectionElement = document.getElementById(
            `section-${firstSectionWithProducts._id}`
          );
          if (sectionElement) {
            const offsetTop =
              sectionElement.offsetTop -
              (sectionsNavRef.current
                ? sectionsNavRef.current.offsetHeight
                : 0) -
              20;
            window.scrollTo({
              top: offsetTop,
              behavior: "smooth",
            });
          }
        }, 100);
      }
    }
  }, [restaurantDetails]);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: [0.25, 0.75],
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && entry.intersectionRatio >= 0.75) {
          setActiveSection(entry.target.id);
        }
      });
    }, options);

    restaurantDetails?.sections
      .filter((section) => section.products && section.products.length > 0)
      .forEach((section) => {
        const element = document.getElementById(`section-${section._id}`);
        if (element) {
          observer.observe(element);
        }
      });

    return () => {
      restaurantDetails?.sections.forEach((section) => {
        const element = document.getElementById(`section-${section._id}`);
        if (element) {
          observer.unobserve(element);
        }
      });
    };
  }, [restaurantDetails]);

  useEffect(() => {
    if (selectedActiveRestaurant) {
      console.log("Restaurant Image URL:", selectedActiveRestaurant.imageUrl);
    }
  }, [selectedActiveRestaurant]);

  const handleSectionClick = (sectionId) => (event) => {
    event.preventDefault();
    const sectionElement = document.getElementById(sectionId);
    if (sectionElement) {
      sectionElement.scrollIntoView({ behavior: "smooth", block: "start" });
      setActiveSection(sectionId);
    }
  };

  const getLinkClasses = (sectionId) => {
    return activeSection === sectionId ? "active-section" : "";
  };

  const handleProductClick = (product) => {
    setSelectedProduct(product);
  };

  const handleCloseDialog = () => {
    setSelectedProduct(null);
  };

  const handleImageLoad = (id, src) => {
    setImageSrcMap((prev) => ({ ...prev, [id]: src }));
  };

  const handleShareClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseShareMenu = () => {
    setAnchorEl(null);
  };

  const handleCopyLink = () => {
    if (!selectedActiveRestaurant || !restaurantDetails) return;

    const formatMenuItem = (item) => {
      const name = item.name;
      const price = `Shs ${item.price.toLocaleString("en-US")}`;
      const dots = ".".repeat(Math.max(3, 35 - (name.length + price.length)));
      return `🍽️ ${name} ${dots} ${price}`;
    };

    const previewUrl = `${API_BASE_URL}/restaurants/${id}`;

    // console.log("Copying URL:", previewUrl);

    const today = new Date().toLocaleDateString("en-US", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    });

    const menuItems = restaurantDetails.sections
      .flatMap(
        (section) =>
          section.products?.filter((product) => product.enabled) || []
      )
      .slice(0, 5);

    const shareText =
      `✨ ${selectedActiveRestaurant.name} Menu ✨\n` +
      `${today}\n` +
      `${"─".repeat(30)}\n\n` +
      `${menuItems.map(formatMenuItem).join("\n")}\n\n` +
      `${"─".repeat(30)}\n\n` +
      `📱 View full menu and order:\n` +
      `${previewUrl}\n\n` +
      `💫 Download Lusuku:\n` +
      `Android: https://play.google.com/store/apps/details?id=com.lusuku` +
      `\niOS: https://apps.apple.com/ug/app/lusuku/id1622613658`;

    navigator.clipboard
      .writeText(shareText)
      .then(() => {
        toast.success("Restaurant details copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
        toast.error("Failed to copy to clipboard");
      });
    setAnchorEl(null);
  };

  const handleWhatsAppShare = () => {
    if (!selectedActiveRestaurant || !restaurantDetails) return;

    const formatMenuItem = (item) => {
      const name = item.name;
      const price = `Shs ${item.price.toLocaleString("en-US")}`;
      const dots = ".".repeat(Math.max(3, 35 - (name.length + price.length)));
      return `🍽️ ${name} ${dots} ${price}`;
    };

    const previewUrl = `${API_BASE_URL}/restaurants/${id}`;

    // console.log("Sharing URL:", previewUrl);

    const today = new Date().toLocaleDateString("en-US", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    });

    const menuItems = restaurantDetails.sections
      .flatMap(
        (section) =>
          section.products?.filter((product) => product.enabled) || []
      )
      .slice(0, 5);

    const shareText =
      `✨ ${selectedActiveRestaurant.name} Menu ✨\n` +
      `${today}\n` +
      `${"─".repeat(30)}\n\n` +
      `${menuItems.map(formatMenuItem).join("\n")}\n\n` +
      `${"─".repeat(30)}\n\n` +
      `📱 View full menu and order:\n` +
      `${previewUrl}\n\n` +
      `💫 Download Lusuku:\n` +
      `Android: https://play.google.com/store/apps/details?id=com.lusuku` +
      `\niOS: https://apps.apple.com/ug/app/lusuku/id1622613658`;

    const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(shareText)}`;
    window.open(whatsappUrl, "_blank");
    setAnchorEl(null);
  };

  if (isFetching || !restaurantDetails) {
    return <LoadingIndicator>Loading...</LoadingIndicator>;
  }

  // Truncate the description if it's too long to 20 words
  const truncateDescription = (description) => {
    const words = description.split(" ");
    return words.length > 20
      ? words.slice(0, 20).join(" ") + "..."
      : description;
  };

  return (
    <Container>
      <RestaurantNavBar setIsCartVisible={setIsCartVisible} />
      {restaurantDetails && (
        <>
          <RestaurantInfo>
            <RestaurantBackground>
              <AvatarWrapper>
                <StyledAvatar
                  alt={selectedActiveRestaurant?.name}
                  src={selectedActiveRestaurant?.imageUrl || placeholderImage}
                />
              </AvatarWrapper>
              <RestaurantText>
                <h1>{selectedActiveRestaurant?.name}</h1>
                <p>{selectedActiveRestaurant?.address}</p>
                <p>⭐⭐⭐⭐</p>
              </RestaurantText>
            </RestaurantBackground>
            <ShareButton onClick={handleShareClick}>
              <ShareIcon />
            </ShareButton>
            <StyledMenu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleCloseShareMenu}
            >
              {selectedActiveRestaurant?.imageUrl && (
                <MenuItem disabled style={{ opacity: 1 }}>
                  <img
                    src={selectedActiveRestaurant.imageUrl}
                    alt={selectedActiveRestaurant.name}
                    style={{
                      width: "100%",
                      height: "120px",
                      objectFit: "cover",
                      borderRadius: "4px",
                    }}
                  />
                </MenuItem>
              )}
              <MenuItem onClick={handleCopyLink}>
                <ListItemIcon>
                  <LinkIcon fontSize="small" style={{ color: "#1976d2" }} />
                </ListItemIcon>
                <ListItemText primary="Copy Shop Link" />
              </MenuItem>
              <MenuItem onClick={handleWhatsAppShare}>
                <ListItemIcon>
                  <WhatsAppIcon fontSize="small" style={{ color: "#25D366" }} />
                </ListItemIcon>
                <ListItemText primary="Share on WhatsApp" />
              </MenuItem>
            </StyledMenu>
          </RestaurantInfo>
          {restaurantDetails.sections &&
          restaurantDetails.sections.filter(
            (section) =>
              section.products &&
              section.products.filter((product) => product.enabled).length > 0
          ).length > 0 ? (
            <>
              <SectionsNav ref={sectionsNavRef}>
                {restaurantDetails.sections
                  .filter(
                    (section) =>
                      section.products &&
                      section.products.filter((product) => product.enabled)
                        .length > 0
                  )
                  .map((section) => (
                    <SectionLink
                      key={section._id}
                      href={`#section-${section._id}`}
                      onClick={handleSectionClick(`section-${section._id}`)}
                      className={getLinkClasses(`section-${section._id}`)}
                    >
                      {section.title}
                    </SectionLink>
                  ))}
              </SectionsNav>
              <SectionsContainer>
                {restaurantDetails.sections
                  .filter(
                    (section) =>
                      section.products &&
                      section.products.filter((product) => product.enabled)
                        .length > 0
                  )
                  .map((section) => (
                    <Section key={section._id} id={`section-${section._id}`}>
                      <SectionHeader>
                        <h2>{section.title}</h2>
                        <p className="section-description">
                          || {section.description}
                        </p>
                      </SectionHeader>
                      <ProductsGrid>
                        {section.products
                          .filter((product) => product.enabled)
                          .map((product) => {
                            const isInCart = Boolean(cart[product._id]);
                            const imageSrc =
                              imageSrcMap[product._id] || placeholderImage;
                            return (
                              <ProductCard
                                key={product._id}
                                onClick={() => handleProductClick(product)}
                              >
                                <img
                                  src={imageSrc}
                                  alt={product.name}
                                  onLoad={() =>
                                    handleImageLoad(product._id, product.image)
                                  }
                                  onError={() =>
                                    handleImageLoad(
                                      product._id,
                                      placeholderImage
                                    )
                                  }
                                />
                                <ProductDetails>
                                  <ProductName>{product.name}</ProductName>
                                  {/* <ProductDescription>{truncateDescription(product.description)}</ProductDescription> */}
                                  <QuillWrapper>
                                    <ReactQuill
                                      value={truncateDescription(
                                        product.description ||
                                          "No description available."
                                      )}
                                      readOnly={true}
                                      theme="bubble"
                                      className="quill"
                                    />
                                  </QuillWrapper>
                                  <PriceAndButtonContainer>
                                    <ProductPrice>
                                      {new Intl.NumberFormat("en-UG", {
                                        style: "currency",
                                        currency: "UGX",
                                      }).format(product.price)}
                                    </ProductPrice>
                                    <Tooltip
                                      title={
                                        isInCart
                                          ? "Item in cart"
                                          : "Add to Cart"
                                      }
                                    >
                                      <AddToCartButton
                                        isInCart={isInCart}
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleProductClick(product);
                                        }}
                                      >
                                        {isInCart ? (
                                          <InCartIcon />
                                        ) : (
                                          <AddToCartIcon />
                                        )}
                                      </AddToCartButton>
                                    </Tooltip>
                                  </PriceAndButtonContainer>
                                </ProductDetails>
                              </ProductCard>
                            );
                          })}
                      </ProductsGrid>
                    </Section>
                  ))}
              </SectionsContainer>
            </>
          ) : (
            <NoProductsMessage>
              No Active Products Available at the moment...
            </NoProductsMessage>
          )}
        </>
      )}
      <Tooltip title="View Cart">
        <FloatingCartIcon onClick={() => setIsCartVisible(!isCartVisible)}>
          <Badge badgeContent={cartCount} color="secondary">
            🛒
          </Badge>
        </FloatingCartIcon>
      </Tooltip>
      {isCartVisible && (
        <CartOverlay>
          <RestaurantCart onClose={() => setIsCartVisible(false)} />
        </CartOverlay>
      )}
      {selectedProduct && (
        <RestaurantProductDialog
          open={Boolean(selectedProduct)}
          onClose={handleCloseDialog}
          product={selectedProduct}
          optionGroups={selectedProduct.optionGroups}
        />
      )}
    </Container>
  );
};

export default RestaurantShopPage;

const Container = styled.div`
  padding: 20px;
  padding-top: 80px; /* Adjust to accommodate navbar height */

  @media (max-width: 768px) {
    padding-top: 165px; /* Adjust for mobile for the vendor banner */
    padding: 10px;
  }
`;

const CartOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const RestaurantInfo = styled.div`
  margin-bottom: 20px;
  position: relative;
  height: 120px; /* Adjust height to match the image */
  width: 100%;
  padding-top: 10px;

  @media (max-width: 768px) {
    height: 100px;
  }
`;

const RestaurantBackground = styled.div`
  background-image: url(${restaurantBackgroundBanner});
  background-size: cover;
  background-position: center;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const StyledAvatar = styled(Avatar)`
  width: 90%;
  height: 90%;
  border-radius: 8px; /* Ensure this is not 50% for non-circular */
  object-fit: contain; /* Fit the image within the container, maintaining aspect ratio */
  padding: 5px; /* Add padding to create space around the image */
  box-sizing: border-box; /* Include padding in the total size */
`;

const AvatarWrapper = styled.div`
  position: absolute;
  left: 20px;
  bottom: 20px;
  width: 120px;
  height: 120px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff; /* Optional: set a background color for contrast */
  border-radius: 10px;
  overflow: hidden;
  height: 90px;
`;

const RestaurantText = styled.div`
  color: white;
  text-align: center;
  background-color: rgba(
    0,
    0,
    0,
    0.7
  ); /* Add a semi-transparent background to make text stand out */
  padding: 10px 20px;
  border-radius: 8px;

  h1 {
    font-size: 1.5em;
    margin: 0;

    @media (max-width: 768px) {
      font-size: 1.2em;
    }
  }

  p {
    margin: 5px 0;
    font-size: 1em;

    @media (max-width: 768px) {
      font-size: 0.9em;
    }
  }
`;

const ShareButton = styled(IconButton)`
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: black;
  // background-color: rgba(0, 0, 0, 0.7);
  background-color: #f2f2f2;
  padding: 12px;
  transition: all 0.3s ease;

  &:hover {
    background-color: #25d366;
    transform: scale(1.1);
  }

  svg {
    font-size: 24px;
  }

  @media (max-width: 768px) {
    padding: 8px;
    svg {
      font-size: 20px;
    }
  }
`;

const SectionsNav = styled.nav`
  position: sticky;
  top: 80px; /* Adjust based on the height of your navbar */
  background-color: white;
  z-index: 1000;
  padding: 10px 0;
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid #ccc;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3); /* Add shadow to section nav */
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  @media (max-width: 768px) {
    top: 170px; /* Adjust for mobile */
    overflow-x: auto; /* Enable horizontal scrolling for smaller screens */
    white-space: nowrap; /* Prevent wrapping of section links */
    margin-bottom: 30px; /* Add some space below the sections nav */
    -webkit-overflow-scrolling: touch; /* Enable smooth scrolling on iOS */
    scrollbar-width: none; /* Hide scrollbar for Firefox */
    &::-webkit-scrollbar {
      display: none; /* Hide scrollbar for WebKit browsers */
    }
  }
`;

const SectionLink = styled.a`
  text-decoration: none;
  color: black;
  padding: 10px 20px;
  font-weight: normal;
  transition: all 0.3s ease;

  &.active-section {
    color: #f24405;
    font-weight: bold;
    border-bottom: 2px solid #84b026;
  }

  &:hover {
    background-color: #f0f0f0;
  }

  @media (max-width: 768px) {
    padding: 5px 10px;
    display: inline-block; /* Ensure links are displayed inline */
  }
`;

const SectionsContainer = styled.div`
  margin-top: 2px;
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  height: calc(100vh - 160px);
  padding-bottom: 80px; /* Add padding at the bottom for better scrolling visibility */

  @media (max-width: 768px) {
    height: calc(100vh - 270px);
    padding-bottom: 100px; /* Slightly more padding for mobile */
  }
`;

const Section = styled.section`
  margin-bottom: 8px;
  padding-top: 30px;
  scroll-snap-align: start;
`;

const SectionHeader = styled.div`
  display: flex;
  align-items: baseline;

  h2 {
    margin: 0;
    font-size: 1.4em;

    @media (max-width: 768px) {
      font-size: 1.2em;
      margin-bottom: 7px;
    }
  }

  .section-description {
    margin: 0 0 0 10px; /* Add some space between title and description */
    font-size: 0.8em; /* Make the description tiny */
    font-style: italic; /* Make the description italic */
    color: #777; /* Lighten the description color */

    @media (max-width: 768px) {
      display: none; /* Hide description on mobile */
    }
  }
`;

const ProductsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px; /* Further reduced gap for even tighter layout */
  padding: 0 8px; /* Reduced container padding */

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
  }
`;

const ProductCard = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  margin-bottom: 12px;
  position: relative;
  cursor: pointer;
  height: 100%;

  img {
    width: 100%;
    max-height: 280px;
    min-height: 280px;
    object-fit: cover; /* Changed from contain to cover */
    background-color: #f8f8f8;
    display: block; /* Changed from flex to block */
    margin: 0; /* Ensure no margins */
    padding: 0; /* Ensure no padding */

    @media (max-width: 768px) {
      max-height: 200px;
      min-height: 200px;
    }
  }
`;

const ProductName = styled.h4`
  font-size: 16px;
  font-weight: 500;
  margin: 0; /* Ensure no extra margin at the top */
  padding: 0; /* Ensure no extra padding at the top */

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const QuillWrapper = styled.div`
  .quill {
    font-size: 12px;
    color: #666;
    line-height: 1.5;
    padding: 0; /* Reset padding to align with ProductName */
    margin-left: -15px;
  }
`;

const ProductDetails = styled.div`
  padding: 10px;
  position: relative;
  padding-bottom: 22px;

  @media (max-width: 768px) {
    padding-bottom: 12px;
  }
`;

const ProductPrice = styled.span`
  font-size: 14px;
  font-weight: bold;
  color: green;
  padding-left: 8px;

  @media (max-width: 768px) {
    font-size: 12px;
    padding-left: 0;
  }
`;

const PriceAndButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
  margin-left: -8px;

  @media (max-width: 768px) {
    margin-left: 0;
  }
`;

const AddToCartButton = styled(
  React.forwardRef(({ isInCart, ...otherProps }, ref) => (
    <IconButton ref={ref} {...otherProps} />
  ))
)`
  background-color: ${(props) => (props.isInCart ? "#e0ffe0" : "#fff")};
  border: 1px solid ${(props) => (props.isInCart ? "#00ff00" : "#e0e0e0")};
  border-radius: 50%;
  padding: 5px;
  box-shadow: 0 0 5px 3px ${(props) => (props.isInCart ? "lime" : "#F28705")};
  &:hover {
    background-color: ${(props) => (props.isInCart ? "#d0ffd0" : "#f0f0f0")};
  }

  @media (max-width: 768px) {
    padding: 3px;
  }
`;

const AddToCartIcon = styled(ShoppingCartIcon)`
  font-size: 2rem;
  color: #f24405;
  cursor: pointer;
  transition: color 0.2s;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  padding: 10px;

  &:hover {
    color: #f24405;
  }

  @media (max-width: 768px) {
    font-size: 1.1rem;
    padding: 6px;
  }
`;

const InCartIcon = styled(CheckCircleIcon)`
  font-size: 2rem;
  color: #84b026;
  cursor: pointer;
  transition: color 0.2s;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  padding: 10px;

  &:hover {
    color: #f24405;
  }

  @media (max-width: 768px) {
    font-size: 1.1rem;
    padding: 6px;
  }
`;

const FloatingCartIcon = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  font-size: 2rem;
  background-color: #00ff00;
  color: white;
  padding: 15px; /* Increase padding */
  border-radius: 75%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: background-color 0.2s; /* Add a transition for smooth color change on hover */

  &:hover {
    background-color: #000b0d;
  }

  @media (max-width: 768px) {
    font-size: 1.5rem;
    padding: 12px;
  }

  @media (max-width: 480px) {
    font-size: 1.2rem;
    padding: 10px;
    bottom: 10px;
    right: 10px;
  }
`;

const LoadingIndicator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 24px;
  color: #333;

  @media (max-width: 768px) {
    font-size: 20px;
  }

  @media (max-width: 480px) {
    font-size: 16px;
  }
`;

const NoProductsMessage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50vh;
  font-size: 18px;
  font-style: italic;
  color: #721c24;
  text-align: center;
  background-color: #f8d7da;
  border: 1px solid #f5c2c7;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  margin: 20px;
  transition: transform 0.3s, box-shadow 0.3s;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 768px) {
    font-size: 20px;
    padding: 15px;
  }

  @media (max-width: 480px) {
    font-size: 16px;
    padding: 10px;
  }

  h1 {
    font-size: 1.8em;
    margin-bottom: 10px;
    color: #721c24;
  }

  p {
    font-size: 1.2em;
    margin: 0;
    color: #721c24;
  }

  svg {
    font-size: 3rem;
    color: #721c24;
    margin-bottom: 15px;
  }
`;

const StyledMenu = styled(Menu)`
  .MuiMenuItem-root {
    padding: 12px 24px;

    &:hover {
      background-color: #f5f5f5;

      .MuiListItemIcon-root {
        transform: scale(1.1);
      }
    }
  }

  .MuiListItemIcon-root {
    transition: transform 0.2s ease;
  }
`;
